import React from "react";
import {DatePicker, Space, Typography} from "antd";

const SelectDate = ({currentDate,onChangeDate}) => {
    return (
        <Space>
            <Typography.Title level={4}>조회 날짜 선택</Typography.Title>
            <DatePicker.RangePicker
                onChange={onChangeDate}
            />
        </Space>
    )
}

export default SelectDate;