import {useAcademyContext} from "../../../provider/academyProvider/useAcademyContext";
import {useSelectedMemberContext} from "../useSelectedMemberContext";
import {useGetTeacherAnswerStatistics} from "../../../api/hook/academy/useGetTeacherAnswerStatistics";
import {useEffect} from "react";
import TeacherAnswerTable from "./teacherAnswerTable";

export default function TeacherAnswerTableContainer({selectedDate,answerTypePrice}){
    const {academyData} = useAcademyContext();
    const {selectedMember} = useSelectedMemberContext();
    const {data, refetch} = useGetTeacherAnswerStatistics(academyData?.value,{
    startDate: selectedDate.startDate,
    endDate: selectedDate.endDate,
        memberList: selectedMember
    })

    useEffect(() => {
        if(!selectedMember || Object.keys(selectedDate).length === 0){
            return;
        }
        const handler = setTimeout(() => {
            console.log('selectedMember!',selectedMember);
            refetch().then();
        }, 2000);

        return () => {
            clearTimeout(handler);
        };
    }, [selectedMember, selectedDate]);

    return <div style={{
        flex: 1
    }}>
        <TeacherAnswerTable answerTypePrice={answerTypePrice} answerData={data}/>
    </div>
}