import styles from './style.module.scss'
import classNames from "classnames";

export default function MemberList({memberData, onSelectMemberItem, selectedMemberData}) {

    return <div>
        <h1>멤버 리스트</h1>
        <ul style={{
            listStyle:'none',
            margin:0,
            padding:0,
            height: 'calc(100vh - 350px)',
            overflowY:'auto',
        }}>
            {
                memberData?.map((member) => (
                    <li key={`member-${member?.memberId}`}
                        className={classNames(styles.billMemberListItem, {
                            [styles.selected]: selectedMemberData?.includes(member?.memberId)
                        })}
                    onClick={() => onSelectMemberItem(member?.memberId)}
                    >
                        <p>{member?.memberName}</p>
                    </li>
                ))

            }
        </ul>

    </div>
}