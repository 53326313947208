import {useQuery} from "@tanstack/react-query";
import {apiGetAcademyMembers} from "api/api-gateway/academy";
import {QUERY_KEY} from "constant";

export const useGetAcademyMembers = (academyId, memberType) => {
  return useQuery({
    queryKey: QUERY_KEY.GET_ACADEMY_MEMBERS(academyId),
    queryFn: () => apiGetAcademyMembers(academyId, memberType),
    enabled: !!academyId,
  })
}