import {useAcademyContext} from "../../../provider/academyProvider/useAcademyContext";
import {useGetAcademyMembers} from "../../../api/hook/academy/useGetAcademyMembers";
import MemberList from "./memberList";
import {useSelectedMemberContext} from "../useSelectedMemberContext";

export default function MemberSelectContainer() {
    const {academyData} = useAcademyContext();
    const {data} = useGetAcademyMembers(academyData?.value,{
        memberType: 'teacher'
    });
    const {selectedMember,handleSelectMember } = useSelectedMemberContext();

    return <MemberList memberData={data?.academyMember} onSelectMemberItem={handleSelectMember} selectedMemberData={selectedMember}  />
}