import React, {useState} from "react";
import styles from './style.module.scss';
import SelectDate from "./SelectDate";
import {useLocalStorage} from "../../common/hook/useLocalStorage";
import AnswerPriceGroup from "./answerPriceGroup";
import MemberSelectContainer from "./memberSelectContainer";
import {SelectMemberProvider} from "./memberSelectProvider";
import TeacherAnswerTableContainer from "./teacherAnswerTableContainer";


const BillPage = () => {
    const [answerTypePrice, setAnswerTypePrice] = useLocalStorage('answerTypePrice', {
        text: 0,
        image: 0,
        video: 0,
        link: 0,
        aiAnswer: 0
    });

    const handleAnswerTypePrice = (type, price) => {
        setAnswerTypePrice({
            ...answerTypePrice,
            [type]: price
        });
    };
    const [date, setDate] = useState('');


    const handleChangeDate = (date) => {
        setDate({
            startDate: date[0] ? date[0].format("YYYY-MM-DD") : "",
            endDate: date[1] ? date[1].format("YYYY-MM-DD") : "",
        })
    }


    return (
        <SelectMemberProvider>
        <div className={styles.billContainer}>
            <SelectDate currentDate={date} onChangeDate={handleChangeDate} />
            <AnswerPriceGroup answerTypePrice={answerTypePrice}  onChangePrice={handleAnswerTypePrice}/>
            <div style={{
                display: 'flex',
                gap: '16px',
                justifyContent: 'center',
            }}>
                <MemberSelectContainer/>
                <TeacherAnswerTableContainer selectedDate={date} answerTypePrice={answerTypePrice}/>
            </div>

            {/*<TeacherAnswerTable answerTypePrice={answerTypePrice} answerData={data}/>*/}
        </div>
        </SelectMemberProvider>

    )
}

export default BillPage;