import {useQuery} from "@tanstack/react-query";
import {QUERY_KEY} from "constant";
import {getTeacherAnswerStatistics} from "../../api-gateway/academy";

export const useGetTeacherAnswerStatistics = (academyId, queryString) => {
  return useQuery({
    queryKey: QUERY_KEY.GET_TEACHER_ANSWER_STATISTICS(academyId),
    queryFn: () => {
     return getTeacherAnswerStatistics(academyId,queryString)
    },
    enabled: !!academyId && queryString && false
  })
}