import axios from "axios";
import constants from "../../../config";


export const apiGetAcademyMembers = async (academyId, querystring) => {
    if(!academyId) throw new Error('academyId is required');
        try{
        const response = await axios.get(`${constants.apiUrl}/api/academy/${academyId}/member`, {
            params: {
                ...(querystring ? querystring : {})
            }
        })
            const {data} = response;
        return data
        }catch(e){
        console.error(e);
        throw e;
        }
}

export const getTeacherAnswerStatistics = async (academyId, querySting) => {
    if(!academyId) throw new Error('academyId is required');
    try {
        const response = await axios.get(   `${constants.apiUrl}/api/academy/${academyId}/member/answer/statistics`, {
            params: {
                ...(querySting ? querySting : {})
            }
        });
        const { data } = response;
        return data.body
    }catch (e) {
     console.error(e);
     throw new Error(`Failed to get teacher answer statistics. academyId: ${academyId}`);
    }
}