import {ConfigProvider, theme} from "antd";
import {AcademyContextProvider} from "./academyProvider/academyProvider";

export default function Providers({children}) {
    return    <ConfigProvider
        theme={{
            algorithm: theme.compactAlgorithm,
            token: {
                colorPrimary: "#F5B21A",
                colorInfo: "#F5B21A",
                colorSuccess: "#F5B21A",
            },
        }}
    >
        <AcademyContextProvider>
            {children}
        </AcademyContextProvider>
    </ConfigProvider>
}