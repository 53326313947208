import {createContext, useState} from "react";

export const AcademyContext = createContext(null);


export function AcademyContextProvider({children}) {
    const [academyData, setAcademyData] = useState(null);

    const handleSelectAcademy = (academyId) => {
        setAcademyData(academyId);
    }

    const value = {
        handleSelectAcademy,
         academyData,
    }
    return (
        <AcademyContext.Provider value={value}>
            {children}
        </AcademyContext.Provider>
    )
}