import {Button, Input, Space, Table} from "antd";
import {useMemo, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";


export default function TeacherAnswerTable({answerTypePrice, answerData}) {

    const changeAnswerData = useMemo(() => {
        return answerData?.map((teacherAnswer) => {
            const { statistics } = teacherAnswer;

            const typeCounts = {
                text: 0,
                image: 0,
                video: 0,
                link: 0,
                aiAnswer: 0
            };

            Object.entries(statistics).forEach(([questionId, answerTypes]) => {
                const availableTypes = Object.entries(answerTypes)
                    .filter(([type, count]) => count > 0)
                    .map(([type, count]) => ({
                        type,
                        price: answerTypePrice[type] || 0
                    }));

                if (availableTypes.length > 0) {
                    const sortedTypes = availableTypes.sort((a, b) => b.price - a.price);
                    const highestType = sortedTypes[0].type;

                    typeCounts[highestType] += 1;
                }
            });

            return {
                ...teacherAnswer,
                ...typeCounts
            };
        });
    }, [answerData, answerTypePrice]);

const dateWithTotal = useMemo(()=> {
    const {text, image, video, link, aiAnswer} = answerTypePrice;
    return changeAnswerData?.map(record => ({
        ...record,
        total: (record.text || 0) * text +
            (record.image || 0) * image +
            (record.video || 0) * video +
            (record.link || 0) * link +
            (record.aiAnswer || 0) * aiAnswer
    }));
} ,[answerTypePrice,changeAnswerData])

    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1677ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        filterDropdownProps: {
            onOpenChange(open) {
                if (open) {
                    setTimeout(() => searchInput.current?.select(), 100);
                }
            },
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });


    const TEACHER_ANSWER_COLUMN =   [
        {
            title: '선생님',
            dataIndex: 'username',
            key: 'username',
            ...getColumnSearchProps('username'),
            sorter: (a, b) => (a.username ?? '').localeCompare(b.username ?? ''),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: '답변 완료 수',
            dataIndex: 'answerCount',
            key: 'answerCount',
            render: (text) => {
                return text || 0;
            },
            sorter: (a, b) => a.answerCount - b.answerCount,
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: '텍스트',
            dataIndex: 'text',
            key: 'text',
            render: (text) => {
                return text || 0;
            },
            sorter: (a, b) => (a.text ?? 0) -( b.text??0),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: '이미지',
            dataIndex: 'image',
            key: 'image',
            render: (text) => {
                return text || 0;
            },
            sorter: (a, b) => (a.image ?? 0) -( b.image??0),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: '비디오',
            dataIndex: 'video',
            key: 'video',
            render: (text) => {
                return text || 0;
            },
            sorter: (a, b) =>  (a.video ?? 0) -( b.video??0),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: '링크',
            dataIndex: 'link',
            key: 'link',
            render: (text) => {
                return text || 0;
            },
            sorter: (a, b) => (a.link ?? 0) -( b.link??0),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: 'AI 답변',
            dataIndex: 'aiAnswer',
            key: 'aiAnswer',
            render: (text) => {
                return text || 0;
            },
            sorter: (a, b) => (a.aiAnswer ?? 0) -( b.aiAnswer??0),
            sortDirections: ['descend', 'ascend'],
        },
        {
            title: '총 답변 금액',
            dataIndex: 'total',
            key: 'total',
            render: (text) => {
                return text || 0;
            },
            sorter: (a, b) => (a.total??0) - (b.total??0),
            sortDirections: ['descend', 'ascend'],
        },
    ];

    return (
        <Table columns={TEACHER_ANSWER_COLUMN} dataSource={dateWithTotal} rowKey={(record)=> record?.teacherId} expandable={{
            expandedRowRender: (record) => {
                return (
                    <ul>
                        {record?.answerQuestions?.map((question) => (
                            <li key={`questionId-${record?.teacherId}-${question?.questionId}`}>
                                <Link to={`https://com.hiq24.co.kr/talk/student/null/${question?.questionId}/${question?.studentId}/${question?.teacherId}`} target="_blank" rel="noopener noreferrer">
                                    {question?.questionId}
                                </Link>
                            </li>
                        ))}
                    </ul>
                )
            },
            rowExpandable: (record) => (record?.answerQuestions?.length ?? 0) > 0
        }}/>
    )
}