import {useState} from "react";

export const useLocalStorage = (key, initialValue) => {
    const getStorageValue = () => {
        try {
            const item = localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error("로컬 스토리지에서 값을 가져오는 중 오류 발생:", error);
            return initialValue;
        }
    };


    const [storedValue, setStoredValue] = useState(getStorageValue);


    const setValue = (value) => {
        try {

            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.error("로컬 스토리지에 값을 저장하는 중 오류 발생:", error);
        }
    };

    return [storedValue, setValue];
};