import {createContext, useState} from "react";

export const SelectMemberContext = createContext(null);


export function SelectMemberProvider ({children}) {
    const [selectedMember, setSelectedMember] = useState([]);

    const handleSelectMember = (memberId) => {
        setSelectedMember((prev) => {
            if(prev?.includes(memberId)){
                return prev.filter((member) => member !== memberId);
            }
            return [...prev, memberId];
        });
    }
    const values = {
        selectedMember,
        handleSelectMember
    }
    return (
        <SelectMemberContext.Provider value={values}>
            {children}
        </SelectMemberContext.Provider>
    )
}