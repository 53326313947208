import {Input} from "antd";

const answerTypePriceConstant =  [ {
    title: '텍스트',
    dataIndex: 'text',
},
    {
        title: '이미지',
        dataIndex: 'image',
    },
    {
        title: '비디오',
        dataIndex: 'video',
    },
    {
        title: '링크',
        dataIndex: 'link',
    },
    {
        title: 'AI 답변',
        dataIndex: 'aiAnswer',
    }

]

export default function AnswerPriceGroup({answerTypePrice,onChangePrice}) {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
          <h1>
              답변 금액 설정
          </h1>
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: '8px',
                maxWidth: '800px'
            }}>
                {
                    answerTypePriceConstant.map((type) => (
                        <label key={`answerTypePrice-${type.dataIndex}`}>
                            {type.title}
                            <Input type="number" value={answerTypePrice[type.dataIndex]} onChange={(e) => onChangePrice(type.dataIndex, e.target.value)}/>
                        </label>
                    ))
                }
            </div>
        </div>
    )
}